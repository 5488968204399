<template>
  <div>
    <div v-if="discounts === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="discounts !== null">
      <v-list>
        <template v-for="(discount, index) in discounts">
          <DiscountListItem
            :key="discount.uuid"
            :discount="discount"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </v-list>
      <div v-intersect.quiet="loadMore" />
      <v-progress-circular
        v-if="isLoadingMore"
        indeterminate
        color="primary"
      />
    </div>
  </div>
</template>

<script>

  import DiscountListItem from "../components/DiscountListItem"

  export default {
    name: 'CategoryList',
    props: {
      discounts: {
        type: Array, 
        default: () => []
      }, 
      length: {
        type: Number, 
        default: 0 
      }, 
      step: {
        type: Number, 
        default: 0
      }
    },
    components: {
      DiscountListItem
    },
    data: () => ({
      startIndex: 0,
      isLoadingMore: false,
    }),
    methods: {
      loadMore: function() {
        this.isLoadingMore = true
        this.length = this.length + this.step
      }
    }
  }
</script>
