<template>
  <div />
</template>

<script>
  export default {
    name: 'DiscountListItem',
    props: {
      discount: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({

    }),
  }
</script>
